/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

$normal-width: 900px;
$max-width: 1200px;
$xmax-width: 1600px;

ion-toolbar.desktop-header {
  max-width: 700px;
  margin: 0 auto;
}

ion-toolbar.desktop-wide-header {
  max-width: $max-width;
  margin: 0 auto;
}

ion-grid.layout-grid,
div.layout-grid {
  max-width: $normal-width;
}

ion-grid.layout-wide-grid,
div.layout-wide-grid {
  max-width: $max-width;
}

ion-grid.layout-xwide-grid,
div.layout-xwide-grid {
  max-width: $xmax-width;
}

ion-refresher {
  z-index: 1;
}

ion-avatar {
  background-color: #e2e2e2;
  margin-right: 15px;
}

.header {
  font-size: 24px;
  color: var(--ion-color-dark);
  font-weight: 700;
  margin-bottom: 2px;
  margin-top: 10px;
}

.subheader {
  font-size: 12px;
  color: #666;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 2px;
  margin-top: 10px;
}

.small-text {
  font-size: 0.8em;
}

ion-toolbar {
  ion-icon {
    font-size: 1.8em !important;
  }
}

form {
  ion-item.invalid {
    --border-color: var(--ion-color-danger);
  }
}

ion-item.full-width-item {
  --padding-start: 0 !important;
  font-size: 16px;

  &.form-outline-item {
    --padding-start: 5px !important;
  }
}

ion-item.clickable {
  cursor: pointer;
}

ion-item.form-outline-item {
  margin-top: 5px;
  margin-bottom: 5px;
  --border-radius: 10px;
  --border-color: var(--ion-color-border-color);
  --border-style: solid;
  --border-width: 1px;

  &.invalid,
  ion-input.invalid {
    --border-color: var(--ion-color-danger);
  }
}

.online-dot {
  height: 15px;
  width: 15px;
  border-radius: 15px;
  background-color: var(--ion-color-success);
  position: absolute;
  bottom: -4px;
  right: -4px;
  border: 3px solid var(--pure-light);
}

.darker-backdrop {
  ion-backdrop {
    --backdrop-opacity: 0.8;
  }
}

.medium-backdrop {
  ion-backdrop {
    --backdrop-opacity: 0.4;
  }
}

ion-content.village-teal-bg-always {
  --background: none;
  background-image: url('./assets/images/background_cover.png');
  background-color: var(--pure-light);
  // background-position: center 200px;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;

  @media (prefers-color-scheme: dark) {
    background-color: linear-gradient(
      rgba(18, 18, 18, 0.25),
      rgba(18, 18, 18, 0.25)
    );
  }
}
ion-content.village-teal-bg {
  @media (max-width: 576px) {
    --background: var(--pure-light);
  }

  @media (min-width: 576px) {
    --background: none;
    background-color: var(--pure-light);
    background-position: left 0;
    background-repeat: no-repeat;
    background-size: cover;

    @media (prefers-color-scheme: dark) {
      background-color: linear-gradient(
        rgba(18, 18, 18, 0.25),
        rgba(18, 18, 18, 0.25)
      );
    }
  }
}

.scroll-row {
  overflow-x: scroll;
  white-space: nowrap;
  padding-right: 20px;

  &::-webkit-scrollbar {
    display: none;
  }
}

.post-message-body {
  white-space: pre-line;
}

ion-card.horizontal-scroll {
  min-width: 320px;
  width: 85vw;
  max-width: 370px;
  white-space: normal;
  margin-right: 5px;
  display: inline-block;
  vertical-align: top;
  margin-top: 15px;
  margin-bottom: 15px;
}

.alertDarkButton {
  color: var(--ion-color-dark) !important;
}

.alertDangerButton {
  color: var(--ion-color-danger) !important;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  // border: 1px solid green;
  -webkit-text-fill-color: var(--ion-color-dark);
  // -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  // transition: background-color 5000s ease-in-out 0s;
}

ion-item {
  --background-activated: none !important;
  --background-focused: none !important;
}

.info-modal .modal-wrapper {
  @media (max-width: 576px) {
    border-radius: 10px;
    height: 60%;
    top: 20%;
    width: 80%;
    left: 10%;
    position: absolute;
    display: block;
  }
}

ion-popover::part(content).wide-popover {
  width: 300px;
}

.wide-popover {
  --width: 300px;
  --max-width: 95vw;
}

.custom-dropzone {
  // height: 100px !important;
  color: var(--ion-color-dark) !important;
  background: var(--ion-color-light) !important;
  &:hover {
    background: var(--ion-color-light-shade) !important;
  }
}

.messages-action-sheet {
  .share {
    color: var(--ion-color-dark) !important;
  }
  .support {
    color: var(--ion-color-danger) !important;
  }
  .exchange {
    color: var(--ion-color-primary) !important;
  }
  .event {
    color: var(--ion-color-tertiary) !important;
  }
}

ion-icon.subtitle-config-button {
  margin-top: -3px;
  font-size: 28px;
  float: right;
  text-transform: none;
}

ion-card.home-tile-card {
  margin-right: 5px;
  min-width: 125px;

  ion-card-subtitle {
    font-size: 10px;
  }

  ion-card-header {
    padding-left: 16px;
    padding-right: 10px;
    padding-bottom: 0;
  }

  ion-card-content {
    padding-right: 5px;
  }

  ion-note,
  ion-icon {
    color: var(--ion-color-dark);
    font-weight: 600;
    font-size: 24px !important;
  }
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%2357ACA3'%2F%3E%3C%2Fsvg%3E") !important;
  right: 20px;
  left: auto;
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%2357ACA3'%2F%3E%3C%2Fsvg%3E") !important;
  left: 10px;
  right: auto;
}

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50%;
  width: 27px;
  height: 25px;
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
  background-size: 27px 44px;
  background-position: center;
  background-repeat: no-repeat;
}

.mentionify,
.mentionified {
  color: rgb(4, 124, 112);
  background-color: rgba(20, 160, 146, 0.1);
  cursor: pointer;
  padding: 0 2px 1px;
  border-radius: 2px;
}

.mentionify {
  &::before {
    content: '@';
  }
}

.mentionified {
  &:hover {
    background-color: rgba(20, 160, 146, 0.2);
  }
}

.mentionify-hidden {
  display: none;
}

ion-toolbar {
  --background: var(--pure-light);
}
